import { DateTime } from 'luxon';
import React, { FunctionComponent, ReactElement, useState } from 'react';
import { FormCheck, Row, Modal, Button, Table } from 'react-bootstrap';
import apis from '../../apis';
import {
  HeaderDisplayKeysWithType,
  InlineEditableTable,
} from '../../components';
import { PHISight, Semester, Student, YearSemClasses } from '../../model';
import {
  SightTableHeader,
  denormalizeStudentSight,
  normalizeStudentSight,
  PHISightCalculators,
} from '../TableHeaders/SightHeader';
type Props = {
  editable: boolean;
  sights: PHISight[];
  student: Student;
  semesters: Semester[];
  yearSems: YearSemClasses[];
  onApiError: (e: any) => void;
  onValueUpdated: () => void;
};

const inlineSightTableHeader: HeaderDisplayKeysWithType<PHISight>[] = [
  { property: 'year', display: '學年' },
  { property: 'sem', display: '學期' },
  ...Object.values(SightTableHeader),
];
const manageIDMap: Record<string, string> = {
  '1': '長效散瞳劑\n(阿托平 Atropine)',
  '2': '短效散瞳劑',
  '3': '其他藥物',
  '4': '配鏡矯治',
  '5': '更換鏡片',
  '6': '遮眼治療',
  '7': '配戴隱形眼鏡',
  '8': '角膜塑型片',
  '9': '視力保健衛教',
  '10': '其他',
  '11': '定期檢查',
};

export const SightSection: FunctionComponent<Props> = ({
  editable,
  sights,
  student,
  semesters,
  yearSems,
  onApiError,
  onValueUpdated,
}) => {
  const [inputing, setInputing] = useState(false);
  const currentSemester = semesters.find((s) => s.isNow);
  const sight = sights.find(
    (s) => s.sem === currentSemester?.sem && s.year === currentSemester.year
  );
  function onValueUpdate({
    periodical,
    ...value
  }: PHISight & { periodical?: any }) {
    apis
      .InsertStudentSight(student.pid, {
        ...value,
        periodical,
        examDate: DateTime.now(),
      })
      .catch(onApiError)
      .then(onValueUpdated);
  }

  yearSems
    .filter(
      (s) =>
        !sights.some((sight) => sight.sem === s.sem && sight.year === s.year)
    )
    .forEach(({ year, sem, yearClassId }) =>
      sights.push({
        sem,
        year,
        yearClassId,
        id: 0,
        eFar: false,
        eNear: false,
        eSan: false,
        eWeak: false,
        eSight99: false,
        isDilated: false,
        isDilating: false,
        noProblem: false,
        manageID: []
      })
    );

  sights.sort((a, b) => a.sem - b.sem + (a.year - b.year) * 100);
  return (
    <>
      <div className="sheet-title">
        視力
        <Button
          key="cell-edit"
          className="border-0 px-2 py-1"
          variant="outline-primary"
          size="sm"
          onClick={() => {
            setInputing(true);
          }}
        >
          <span className="feather icon-edit" />
        </Button>
      </div>
      <Table bordered className="student-phi">
        <tbody>
          <tr>
            <th>裸視</th>
            <td>
              左：{sight?.sight0L} 右：{sight?.sight0R}
            </td>
          </tr>
          <tr>
            <th>戴鏡</th>
            <td>
              左：{sight?.sightL} 右：{sight?.sightR}
            </td>
          </tr>
          <tr>
            <th>屈光度數</th>
            <td>
              左：{sight?.diopL} 右：{sight?.diopR}
            </td>
          </tr>
          <tr>
            <th>近視</th>
            <td>
              左：{sight?.eNearL} 右：{sight?.eNearR}
            </td>
          </tr>
          <tr>
            <th>遠視</th>
            <td>
              左：{sight?.eFarL} 右：{sight?.eFarR}
            </td>
          </tr>
          <tr>
            <th>散光</th>
            <td>
              左：{sight?.eSanL} 右：{sight?.eSanR}
            </td>
          </tr>
          <tr>
            <th>症狀</th>
            <td>
              <Row className="m-0">
                {generateCheckBox('近', sight?.eNear)}
                {generateCheckBox('遠', sight?.eFar)}
                {generateCheckBox('弱', sight?.eWeak)}
                {generateCheckBox('散', sight?.eSan)}
                {generateCheckBox('其他', sight?.eSight99)}
              </Row>
            </td>
          </tr>
          <tr>
            <th>處置</th>
            <td>{sight?.manageID ? sight?.manageID.map(v => manageIDMap[v]).join('、') : ''}</td>
          </tr>
        </tbody>
      </Table>
      <Modal
        show={inputing}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        dialogClassName="modal-dialog-full"
        centered
      >
        <Modal.Header closeButton onHide={() => setInputing(false)}>
          <Modal.Title id="contained-modal-title-vcenter">
            視力數據 -{' '}
            <strong>
              {student.seat} {student.name}
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-auto">
          <div className='mb-3'>
            視力請直接輸入整數，例如 0.8 輸入 8 視力值＜0.1請輸入-1<br />
            「散瞳治療」是指點長效型散瞳眼藥治療，「散瞳」是指檢驗屈光度數前的短效散瞳。<br />
            裸眼視力無法測量代號說明：-8 戴隱形眼鏡；-7 角膜塑型；-6 雷射治療；-5 全盲<br />
            無法表達或在家自學僅輸入裸視右眼為 -9<br />
            「定期檢查」日期格式：1999/01/01 或點兩下顯示萬年曆選單<br />
            如「近視右（左）」或「遠視右（左）」有小數點，請將小數點四捨五入至個位數後以整數輸入<br />
          </div>
          <InlineEditableTable
            decorators={PHISightCalculators}
            values={sights}
            headers={inlineSightTableHeader}
            onValueUpdate={onValueUpdate}
            onRowRender={(v) => ({
              editable:
                editable &&
                semesters.some((s) => s.sem === v.sem && s.year === v.year),
            })}
            beforeEdit={({ id, yearClassId, year, sem, ...s }) => ({
              id,
              year,
              sem,
              yearClassId,
              ...denormalizeStudentSight(s),
            })}
            afterEdit={(s) => ({ ...s, ...normalizeStudentSight(s) })}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

function generateCheckBox(
  label: string,
  value: boolean | undefined
): ReactElement {
  return (
    <div>
      {label}
      <FormCheck inline readOnly checked={!!value} />
    </div>
  );
}
