import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import {
  AuthedLayout,
  EditableTableDiv,
  HeaderDisplayKeysWithType,
} from '../../../components';
import { useSemGrade } from '../../../hook';
import { Sight, StudentGradeNoSeat } from '../../../model';
import apis from '../../../apis';
import { SightContext } from '../../TableHeaders/SightHeader';
import { SheetHeaderDisplay, downloadDataAsExcel } from '../../../utils';
import { DateTime } from 'luxon';

type PageData = StudentGradeNoSeat & Sight;

function boolRender(v: unknown) {
  return v ? '是' : '否';
}

const tableHeader: (SheetHeaderDisplay<PageData> &
  HeaderDisplayKeysWithType<PageData>)[] = [
  { display: '年級', property: 'grade' },
  { display: '班級', property: 'no' },
  { display: '座號', property: 'seat' },
  { display: '學生', property: 'name' },
  {
    display: '性別',
    property: 'sex',
    onRender: (v) => (v === '1' ? '男' : '女'),
    onSheetRender: (v) => (v === '1' ? '男' : '女'),
  },
  {
    display: '散瞳治療',
    property: 'isDilated',
    onRender: boolRender,
    onSheetRender: boolRender,
  },
  { display: '裸視右', property: 'sight0R' },
  { display: '裸視左', property: 'sight0L' },
  { display: '戴鏡右', property: 'sightR' },
  { display: '戴鏡左', property: 'sightL' },
  {
    display: '散瞳',
    property: 'isDilating',
    onRender: boolRender,
    onSheetRender: boolRender,
  },
  { display: '近視右', property: 'eNearR' },
  { display: '近視左', property: 'eNearL' },
  { display: '遠視右', property: 'eFarR' },
  { display: '遠視左', property: 'eFarL' },
  { display: '散光右', property: 'eSanR' },
  { display: '散光左', property: 'eSanL' },
  {
    display: '近視',
    property: 'eNear',
    onRender: boolRender,
    onSheetRender: boolRender,
  },
  {
    display: '遠視',
    property: 'eFar',
    onRender: boolRender,
    onSheetRender: boolRender,
  },
  {
    display: '散光',
    property: 'eSan',
    onRender: boolRender,
    onSheetRender: boolRender,
  },
  {
    display: '弱視',
    property: 'eWeak',
    onRender: boolRender,
    onSheetRender: boolRender,
  },
  {
    display: '其他',
    property: 'eSight99',
    onRender: boolRender,
    onSheetRender: boolRender,
  },
  {
    display: '複檢無異狀',
    property: 'noProblem',
    onRender: boolRender,
    onSheetRender: boolRender,
  },
  { display: '備註', property: 'eSight99State' },
  {
    display: '處置代號',
    property: 'manageID',
    onRender: (v) => (Array.isArray(v) ? v.join(',') : v),
    onSheetRender: (v) => (Array.isArray(v) ? v.join(',') : (v as string)),
  },
  {
    display: '醫師建議處置',
    property: 'manage',
  },
  { display: '定期檢查', property: 'periodical',
    onRender: (v) => {
      return v instanceof DateTime ? v.toFormat('yyyy/MM/dd hh:mm') : null;
    },
    onSheetRender: (v) => {
      return v instanceof DateTime ? v.toFormat('yyyy/MM/dd hh:mm') : "";
    },
   },
];

const mapState = (app: ApplicationState) => ({ ...app.auth });
const mapDispatch = ErrorDispatches;

const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const sightNoDiagNotice: FunctionComponent<Props> = ({
  user,
  catchErrorForModal,
}) => {
  const { yearSem, element } = useSemGrade();
  const [students, setStudents] = useState<PageData[]>([]);

  useEffect(() => {
    if (yearSem) {
      apis
        .getNoDiagSight(yearSem.year, yearSem.sem)
        .then((r) =>
          setStudents(r.map(({ sight, ...s }) => ({ ...sight, ...s })))
        )
        .catch(catchErrorForModal);
    }
  }, [yearSem?.year, yearSem?.sem]);

  return (
    <AuthedLayout>
      <Row className="justify-content-between">
        <span className="mr-3">{element}</span>
        <Button
          disabled={!students.length}
          variant="success"
          className="text-dark"
          onClick={() => {
            downloadDataAsExcel({
              title: `${yearSem?.year}學年${yearSem?.sem}學期_裸眼視力不良但未有診斷名單`,
              values: students,
              headers: tableHeader,
              context: SightContext,
              footer:
                '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
            });
          }}
        >
          Excel 下載
        </Button>
      </Row>
      <hr />
      <Row>
        <EditableTableDiv
          headers={tableHeader}
          values={students}
          context={SightContext}
        />
      </Row>
    </AuthedLayout>
  );
};

export const SightNoDiagNotice = connector(sightNoDiagNotice);
