import { z } from 'zod';
import {
  ClassSettingZod,
  ClassZod,
  StudentPHIWHZod,
  StudentFile,
  StudentSightZod,
  StudentTeeZod,
  StudentZod,
  ClassStudent,
  StudentAllPHIZod,
  GraduatedClassZod,
  ClassStudentResponse,
  ClassStudentResponseZod,
  CheckField,
  ClassSetting,
  ClassStudentZod,
  YearSem,
  SchoolClassNameEnum,
} from '../model';
import { delet, get, post, put } from './BaseApi';

const classesZod = z.array(ClassZod);
const ClassesStudentGrade = ClassStudentZod;
type ClassesStudentGradeType = z.infer<typeof ClassesStudentGrade>;
type Classes = z.infer<typeof classesZod>;
export async function getClasses(
  year?: number,
  sem?: number
): Promise<Classes> {
  return get('/api/classes', classesZod, { year, sem });
}

export async function getNoticeClasses(
  year: number,
  sem: number
): Promise<Classes> {
  return get('/api/classes/notice', classesZod, { year, sem });
}

const graduatedClassesZod = z.array(GraduatedClassZod);
type GraduatedClasses = z.infer<typeof graduatedClassesZod>;
export async function getGraduatedClasses(): Promise<GraduatedClasses> {
  return get('/api/classes/graduated', graduatedClassesZod);
}

const studentsZod = z.array(StudentZod);
type Students = z.infer<typeof studentsZod>;
export async function getClassStudents(
  classId: string,
  year: number,
  sem: number
): Promise<Students> {
  return get(`/api/class/${classId}/students`, studentsZod, { year, sem });
}

const studentPhiWHsZod = z.array(StudentPHIWHZod);
export async function getClassStudentMetric(
  classId: string,
  year: number,
  sem: number
): Promise<z.infer<typeof studentPhiWHsZod>> {
  return get(`/api/class/${classId}/students/metric`, studentPhiWHsZod, {
    year,
    sem,
  });
}

const studentSightsZod = z.array(StudentSightZod);
export async function getClassStudentSight(
  classId: string,
  year: number,
  sem: number
): Promise<z.infer<typeof studentSightsZod>> {
  return get(`/api/class/${classId}/phi/sight`, studentSightsZod, {
    year,
    sem,
  });
}

const studentTeeZod = z.array(StudentTeeZod);
export async function getClassStudentTee(
  classId: string,
  year: number,
  sem: number
): Promise<z.infer<typeof studentTeeZod>> {
  return get(`/api/class/${classId}/phi/tee`, studentTeeZod, {
    year,
    sem,
  });
}

const studentAllPHIZod = z.array(StudentAllPHIZod);
export async function getClassStudentAllPHI(
  classId: string,
  year: number,
  sem: number
): Promise<z.infer<typeof studentAllPHIZod>> {
  return get(`/api/class/${classId}/phi/all`, studentAllPHIZod, {
    year,
    sem,
  });
}

const cserZod = z.object({
  students: z.array(ClassStudentResponseZod),
});
export async function checkClassesStudentExists(
  students: ClassStudent[],
  checkField: CheckField,
  yearSem: { year: number; sem: number }
): Promise<ClassStudentResponse[]> {
  const response = await post(
    `/api/classes/students/exists`,
    cserZod,
    {
      students,
      checkField,
    },
    yearSem
  );
  return response.students;
}
const cserSeatZod = z.object({
  students: z.array(ClassStudentResponseZod),
  seatStudents: z.array(ClassStudentResponseZod),
});
type CheckClassesStudentExistsResponse = z.infer<typeof cserSeatZod>;
export async function checkClassesSeatStudentExists(
  students: ClassStudent[],
  checkField: CheckField,
  yearSem: { year: number; sem: number }
): Promise<CheckClassesStudentExistsResponse> {
  const response = await post(
    `/api/classes/students/seat/exists`,
    cserSeatZod,
    {
      students,
      checkField,
    },
    yearSem
  );
  return response;
}
export async function checkClassesStudentExistsWithGrade(
  students: ClassStudent[],
  checkField: CheckField
): Promise<ClassesStudentGradeType> {
  const res = await post(
    `/api/classes/students/exists/grade`,
    ClassesStudentGrade,
    {
      students,
      checkField,
    }
  );
  return res;
}

export async function insertClassesStudent(
  students: StudentFile[],
  yearSem: YearSem
): Promise<void> {
  return put(`/api/classes/students`, { students }, yearSem);
}

export async function updateClassesStudent(
  students: StudentFile[],
  yearSem: YearSem
): Promise<void> {
  return put(`/api/classes/students/update`, { students }, yearSem);
}

const classSettingsZod = z.array(ClassSettingZod);
export async function getClassSettings(
  year: number,
  sem: number
): Promise<z.infer<typeof classSettingsZod>> {
  return get(`/api/classes/settings`, classSettingsZod, { year, sem });
}

export async function removeEmptyClasses(year: number): Promise<void> {
  return delet(`/api/classes/empty`, {}, { year });
}

export async function updateClass(
  id: string,
  klass: ClassSetting,
  year: number,
  sem: number
): Promise<void> {
  return post(`/api/classes/${id}`, klass, { year, sem });
}

export async function deleteClass(id: string, year: number): Promise<void> {
  return delet(`/api/classes/${id}`, {}, { year });
}

export async function createClass(
  klass: { grade: number; count: number },
  year: number
): Promise<void> {
  return put(`/api/classes/`, klass, { year });
}

export async function updateClassesCheckTee(
  year: number,
  sem: number,
  check: boolean
): Promise<void> {
  return post(`/api/classes/tee`, {}, { year, sem, check });
}

export async function setClassesDefaultName(
  nameEnum: SchoolClassNameEnum,
  year: number
): Promise<void> {
  return post(`/api/classes/defaultname`, { enum: nameEnum }, { year });
}
