import { z } from 'zod';
import { DateTime } from 'luxon';
import { Primitive } from '../types';
import { zodStringToDateTime } from '../utils/zod';
import {
  ExaminedResultEnumZod,
  InjuryMomentEnumZod,
  InjuryPlaceEnum,
  InjuryPlaceEnumZod,
  LeftRightEnumZod,
  StrabismusKindEnumZod,
  TeeInspectionZod,
  UrineEnumZod,
} from './Enums';

export const StudentZod = z.object({
  id: z.string(),
  pid: z.string(),
  sid: z.string().nullish(),
  name: z.string(),
  seat: z.number().nullish(),
  classGrade: z.number().optional(),
  classNo: z.number().optional(),
  sex: z.string(),
  birth: z.string().transform((s) => DateTime.fromISO(s)),
  year: z.number(),
  dad: z.string().nullish(),
  mom: z.string().nullish(),
  guardian: z.string().nullish(),
  zip: z.string().nullish(),
  tel: z.string().nullish(),
  erTel: z.string().nullish(),
  address: z.string().nullish(),
  blood: z.string().nullish(),
  remark: z.string().nullish(),
  aborigine: z.string().nullish(),
});

export const PHIWHZod = z.object({
  id: z.number(),
  sem: z.number(),
  year: z.number(),
  height: z.number().nullish(),
  weight: z.number().nullish(),
  bmi: z.number().nullish(),
  bmiCode: z.string().nullish(),
  yearClassId: z.number(),
  examDate: zodStringToDateTime().nullish(),
  midAge: z.number().nullish(),
  classGrade: z.number().nullish(),
});

export const WHZod = z.object({
  height: z.number().nullish(),
  weight: z.number().nullish(),
  bmi: z.number().nullish(),
  bmiCode: z.string().nullish(),
  examDateWH: zodStringToDateTime(),
});

export const PHIDiseaseZod = z.object({
  id: z.number(),
  sem: z.number(),
  year: z.number(),
  studentId: z.string(),
  description: z.string(),
  diseaseId: z.number(),
  diseaseName: z.string(),
  treatment: z.string(),
  enterDate: zodStringToDateTime(),
  yearClassId: z.number(),
});

export const StudentHospitalZod = z.object({
  hospitalId: z.number(),
  hospitalName: z.string(),
  studentId: z.string(),
});
export const StudentInheritDiseaseZod = z.object({
  id: z.number(),
  inheritDisease: z.number(),
  relative: z.number(),
  states: z.string().nullish(),
  studentId: z.string(),
});

export const PHISeriousDiseaseZod = z.object({
  id: z.number(),
  sem: z.number(),
  year: z.number(),
  studentId: z.string(),
  diseaseId: z.number(),
  diseaseName: z.string(),
});

export const PHIBodyMindBookNZod = z.object({
  id: z.number(),
  sem: z.number(),
  year: z.number(),
  studentId: z.string(),
  bodyMindId: z.number(),
  bodyMindName: z.string(),
  degree: z.number(),
});

export const PHISightSolidZod = z.object({
  id: z.number(),
  studentId: z.string(),
  sightSolid: z.number().nullish(),
  states: z.string().nullish(),
  hospital: z.string().nullish(),
});

export const PHIInheritDiseaseZod = z.object({
  id: z.number(),
  inheritDisease: z.number(),
  relative: z.number(),
  states: z.string().nullish(),
  studentId: z.string(),
});

export const PHIInsuranceZod = z.object({
  id: z.number(),
  insurance: z.number(),
  states: z.string().nullish(),
  studentId: z.string(),
});

export const SightZod = z.object({
  sight0L: z
    .number()
    .nullish()
    .transform((v) => (v == 0 ? null : v)),
  sight0R: z
    .number()
    .nullish()
    .transform((v) => (v == 0 ? null : v)),
  sightL: z
    .number()
    .nullish()
    .transform((v) => (v == 0 ? null : v)),
  sightR: z
    .number()
    .nullish()
    .transform((v) => (v == 0 ? null : v)),
  eNear: z.boolean().default(false),
  eNearL: z
    .number()
    .nullish()
    .transform((v) => (v == 0 ? null : v)),
  eNearR: z
    .number()
    .nullish()
    .transform((v) => (v == 0 ? null : v)),
  eFar: z.boolean().default(false),
  eFarL: z
    .number()
    .nullish()
    .transform((v) => (v == 0 ? null : v)),
  eFarR: z
    .number()
    .nullish()
    .transform((v) => (v == 0 ? null : v)),
  eSan: z.boolean().default(false),
  eSanL: z
    .number()
    .nullish()
    .transform((v) => (v == 0 ? null : v)),
  eSanR: z
    .number()
    .nullish()
    .transform((v) => (v == 0 ? null : v)),
  eWeak: z.boolean().default(false),
  eSight99: z.boolean().default(false),
  eSight99State: z.string().nullish(),
  manageID: z.array(z.string()).nullish(),
  manage: z.string().nullish(),
  diopL: z
    .number()
    .nullish()
    .transform((v) => (v == 0 ? null : v)),
  diopR: z
    .number()
    .nullish()
    .transform((v) => (v == 0 ? null : v)),
  noProblem: z.boolean().default(false),
  isDilated: z.boolean().default(false),
  isDilating: z.boolean().default(false),
  periodical: zodStringToDateTime().nullish(),
  examDate: zodStringToDateTime(),
  measureResult: z.string().nullish(),
  differ: z.number().nullish(),
  sight0Level: z.number().nullish(),
});

export const PHISightZod = SightZod.merge(
  z.object({
    id: z.number(),
    year: z.number(),
    sem: z.number(),
    yearClassId: z.number(),
    classGrade: z.number().nullish(),
  })
);

export const PHITeeZod = z.object({
  id: z.number(),
  year: z.number(),
  sem: z.number(),
  tee: z.boolean().default(false),
  t01: ExaminedResultEnumZod,
  t02: ExaminedResultEnumZod,
  t04: ExaminedResultEnumZod,
  t05: ExaminedResultEnumZod,
  t06: z.number(),
  t07: ExaminedResultEnumZod,
  t08: ExaminedResultEnumZod,
  t99: ExaminedResultEnumZod,
  t09: ExaminedResultEnumZod,
  t11: TeeInspectionZod,
  t12: TeeInspectionZod,
  t13: TeeInspectionZod,
  t14: TeeInspectionZod,
  t15: TeeInspectionZod,
  t16: ExaminedResultEnumZod,
  t17: ExaminedResultEnumZod,
  t03: ExaminedResultEnumZod,
  t18: ExaminedResultEnumZod,
  t19: ExaminedResultEnumZod,
  t99state: z.string().nullish(),
  reT99state: z.string().nullish(),
  tSheet: z.record(z.string()).nullish(),
  reTSheet: z.record(z.string()).nullish(),
  teedoctor: z.string().nullish(),
  examDate: zodStringToDateTime(),
  yearClassId: z.number(),
});

export const PHISharedZod = z.object({
  id: z.number(),
  year: z.number(),
  sem: z.number(),
  grade: z.number(),
});

export const PHIPhysicalZod = PHISharedZod.merge(
  z.object({
    sbp: z.number().nullish(),
    dbp: z.number().nullish(),
    bpResult: ExaminedResultEnumZod,
    waistline: z.number().nullish(),
    waistResult: ExaminedResultEnumZod,
    pulse: z.number().nullish(),
    pulseResult: ExaminedResultEnumZod,
    colorBlind: ExaminedResultEnumZod,
    e99: ExaminedResultEnumZod,
    e99State: z.string().nullish(),
    hearing: ExaminedResultEnumZod,
    hearingLR: LeftRightEnumZod,
    earMisshapen: ExaminedResultEnumZod,
    earMisshapenLR: LeftRightEnumZod,
    eardrum: ExaminedResultEnumZod,
    eardrumLR: LeftRightEnumZod,
    cerumen: ExaminedResultEnumZod,
    cerumenLR: LeftRightEnumZod,
    tonsillitis: ExaminedResultEnumZod,
    o99: ExaminedResultEnumZod,
    o99State: z.string().nullish(),
    torticollis: ExaminedResultEnumZod,
    mass: ExaminedResultEnumZod,
    goiter: ExaminedResultEnumZod,
    lymphadenectasis: ExaminedResultEnumZod,
    mass99: ExaminedResultEnumZod,
    n99: ExaminedResultEnumZod,
    n99State: z.string().nullish(),
    cardiopulmonary: ExaminedResultEnumZod,
    heartMurmur: ExaminedResultEnumZod,
    arrhythmia: ExaminedResultEnumZod,
    wheeze: ExaminedResultEnumZod,
    cardiopulmonary99: ExaminedResultEnumZod,
    thorax: ExaminedResultEnumZod,
    c99: ExaminedResultEnumZod,
    c99State: z.string().nullish(),
    abdomen: ExaminedResultEnumZod,
    a99: ExaminedResultEnumZod,
    a99State: z.string().nullish(),
    scoliosis: ExaminedResultEnumZod,
    dysmelia: ExaminedResultEnumZod,
    polydactyly: ExaminedResultEnumZod,
    dysarthrosis: ExaminedResultEnumZod,
    dysmelia99: ExaminedResultEnumZod,
    squatting: ExaminedResultEnumZod,
    l99: ExaminedResultEnumZod,
    l99State: z.string().nullish(),
    cryptorchidism: ExaminedResultEnumZod,
    prepuce: ExaminedResultEnumZod,
    varicocele: ExaminedResultEnumZod,
    u99: ExaminedResultEnumZod,
    u99State: z.string().nullish(),
    epidermophytosis: ExaminedResultEnumZod,
    scabies: ExaminedResultEnumZod,
    wart: ExaminedResultEnumZod,
    atopicDermatitis: ExaminedResultEnumZod,
    eczema: ExaminedResultEnumZod,
    d99: ExaminedResultEnumZod,
    d99State: z.string().nullish(),
    t01: ExaminedResultEnumZod,
    t02: ExaminedResultEnumZod,
    t03: ExaminedResultEnumZod,
    t04: ExaminedResultEnumZod,
    t05: ExaminedResultEnumZod,
    t06: z.number(),
    t07: ExaminedResultEnumZod,
    t08: ExaminedResultEnumZod,
    t99: ExaminedResultEnumZod,
    t99state: z.string().nullish(),
    tSheet: z.record(z.string()).nullish(),
    t09: ExaminedResultEnumZod,
    t11: TeeInspectionZod,
    t12: TeeInspectionZod,
    t13: TeeInspectionZod,
    t15: TeeInspectionZod,
    t16: ExaminedResultEnumZod,
    t17: ExaminedResultEnumZod,
    t18: ExaminedResultEnumZod,
    t19: ExaminedResultEnumZod,
    reT99state: z.string().nullish(),
    reTSheet: z.record(z.string()).nullish(),
    strabismus: ExaminedResultEnumZod,
    strabismusType: StrabismusKindEnumZod,
    trichiasis: ExaminedResultEnumZod,
    nystagmus: ExaminedResultEnumZod,
    blepharoptosis: ExaminedResultEnumZod,
    clp: ExaminedResultEnumZod,
    articulationDisorders: ExaminedResultEnumZod,
    preauricularFistula: ExaminedResultEnumZod,
    preauricularFistulaLR: z.number(),
    rhinitis: ExaminedResultEnumZod,
    allergicRhinitis: ExaminedResultEnumZod,
    hernia: ExaminedResultEnumZod,
    edema: ExaminedResultEnumZod,
    scrotalSwelling: ExaminedResultEnumZod,
    purpura: ExaminedResultEnumZod,
    checkUnit: z.string().nullish(),
    doctor: z.string().nullish(),
    teeDoctor: z.string().nullish(),
    examDate: zodStringToDateTime(),
    yearClassId: z.number(),
  })
);

// export const PHILabZod = z.object({
//   id: z.number(),
//   sem: z.number(),
//   year: z.number(),
//   inspect: z.number().nullish(),
//   urine: z.string().nullish(), //zodStringOrNumberToNumber, zodStringOrNumberToString()
//   uProtein: z.string().nullish(),
//   uOB: z.string().nullish(),
//   uGlucose: z.string().nullish(),
//   uPh: z.string().nullish(),
//   uProtein2: z.string().nullish(),
//   uOB2: z.string().nullish(),
//   uGlucose2: z.string().nullish(),
//   uPh2: z.string().nullish(),
//   parasite: z.string().nullish(),
//   yearClassId: z.number(),
//   examDate: zodStringToDateTime(),
// });

export const PHISundryWithStudentIdZod = PHISharedZod.merge(
  z.object({
    studentId: z.string(),
    sundryId: z.number(),
    agencies: z.string(),
    sundryResult: z.number(),
    yearClassId: z.number(),
    examDate: zodStringToDateTime(),
    followUpDescription: z.string().nullish(),
    remark: z.string().nullish(),
  })
);

export const PHIUrinePhZod = z.object({
  uPh1: z.number().nullish(),
  uPh2: z.number().nullish(),
});

export const PHISundryZod = z.object({
  id: z.number(),
  sem: z.number(),
  year: z.number(),
  grade: z.number(),
  sundryId: z.number(),
  agencies: z.string(),
  sundryResult: z.number(),
  yearClassId: z.number(),
  examDate: zodStringToDateTime(),
  followUpDescription: z.string().nullish(),
  remark: z.string().nullish(),
});

export const PHISundryGroupByGradeClassZod = z.object({
  grade: z.number(),
  sundryId: z.number(),
  agencies: z.string(),
  examDate: zodStringToDateTime(),
  idsString: z.string(),
});

export const SundryExamDateZod = z.object({
  examDate: zodStringToDateTime(),
});

export const StudentDiseaseZod = z.object({
  id: z.number(),
  sem: z.number(),
  year: z.number(),
  studentId: z.string(),
  description: z.string(),
  diseaseId: z.number(),
  diseaseName: z.string(),
  treatment: z.string(),
  enterDate: zodStringToDateTime(),
  yearClassId: z.number(),
});

export const TeeFluorLackZod = z.object({
  sem: z.number(),
  year: z.number(),
  id: z.number(),
  participate: z.boolean(),
  lackCount: z.number(),
  fluorId: z.number(),
  studentId: z.string(),
});
export const NeverFluorZod = z.object({
  id: z.number(),
  schoolId: z.string(),
  studentId: z.string(),
  name: z.string(),
  grade: z.number(),
  no: z.number(),
  seat: z.number(),
});
export const StudentFluorZod = z.object({
  id: z.number(),
  participate: z.boolean(),
  lackCount: z.number(),
  studentId: z.string(),
  fluorId: z.number(),
  name: z.string(),
  grade: z.number(),
  no: z.number(),
  seat: z.number(),
});

export const BrushTeeLackZod = z.object({
  sem: z.number(),
  year: z.number(),
  id: z.number(),
  lackMonth: z.number(),
  lack: z.number(),
  studentId: z.string(),
});
export const BodyMindNZod = z.object({
  id: z.number(),
  studentId: z.string(),
  bodyMindId: z.number(),
  degree: z.number(),
  createDate: zodStringToDateTime(),
  bodyMindName: z.string(),
});

export const StudentDiseaseBodyMindZod = z.object({
  bodyMindName: z.string(),
  description: z.string(),
  treatment: z.string(),
  seriousDiseaseName: z.string(),
  diseaseName: z.string(),
  hospital: z.string(),
});

export const SeriousDiseaseZod = z.object({
  id: z.number(),
  diseaseId: z.number(),
  diseaseName: z.string(),
});

export const DiseaseZod = z.object({
  id: z.number(),
  name: z.string(),
  treatment: z.string(),
  order: z.number(),
});
export const SeriousDiseaseKindZod = z.object({
  id: z.number(),
  disease: z.string(),
});

export const SimpleStudentZod = z.object({
  pid: z.string(),
  sid: z.string(),
  name: z.string(),
  seat: z.number().nullish(),
  sex: z.string().nullish(),
});

export const StudentGradeNoSeatZod = z.object({
  pid: z.string(),
  name: z.string(),
  sid: z.string().nullish(),
  sex: z.string(),
  grade: z.number(),
  no: z.number(),
  seat: z.number(),
});

export const StudentGradeNoSeatYearSemZod = z.object({
  pid: z.string(),
  name: z.string(),
  schoolName: z.string(),
  grade: z.number(),
  no: z.number(),
  className: z.string(),
  seat: z.number(),
  year: z.number(),
  sem: z.number(),
});

export const StudentGradeNoSeatBirthZod = StudentGradeNoSeatZod.merge(
  z.object({
    birth: z.string().transform((s) => DateTime.fromISO(s)),
    fitWeight: z.number().nullish(),
    fitHeight: z.string().nullish(),
    fitBMI: z.number().nullish(),
  })
);

export const StudentWithClassGradeZod = z.object({
  sid: z.string(),
  seat: z.number(),
  classGrade: z.number(),
  classNo: z.number(),
  year: z.number(),
  sem: z.number(),
});

export const StudentPHIWHZod = z.object({
  pid: z.string(),
  sid: z.string(),
  name: z.string(),
  seat: z.number().nullish(),
  phiWHs: z.array(PHIWHZod),
});

export const StudentWHZod = StudentGradeNoSeatZod.merge(
  z.object({
    phiWHs: z.array(PHIWHZod),
  })
);

export const StudentSightZod = SimpleStudentZod.merge(
  z.object({ sight: SightZod.nullish() })
);

export const YearSemClassesZod = z.object({
  year: z.number().int(),
  sem: z.number().int(),
  yearClassId: z.number().int(),
  no: z.number().int(),
  grade: z.number().int(),
});

export const PHIBloodZod = PHISharedZod.merge(
  z.object({
    hb: z.number().nullish(),
    wbc: z.number().nullish(),
    rbc: z.number().nullish(),
    pl: z.number().nullish(),
    mcv: z.number().nullish(),
    ht: z.number().nullish(),
    cl: z.number().nullish(),
    cr: z.number().nullish(),
    ua: z.number().nullish(),
    bun: z.number().nullish(),
    got: z.number().nullish(),
    gpt: z.number().nullish(),
    hbsa: z.number().nullish(),
    antiHB: z.number().nullish(),
    hbResult: ExaminedResultEnumZod,
    wbcResult: ExaminedResultEnumZod,
    rbcResult: ExaminedResultEnumZod,
    plResult: ExaminedResultEnumZod,
    mcvResult: ExaminedResultEnumZod,
    htResult: ExaminedResultEnumZod,
    clResult: ExaminedResultEnumZod,
    crResult: ExaminedResultEnumZod,
    uaResult: ExaminedResultEnumZod,
    bunResult: ExaminedResultEnumZod,
    gotResult: ExaminedResultEnumZod,
    gptResult: ExaminedResultEnumZod,
    hbsaResult: ExaminedResultEnumZod,
    antiHBResult: ExaminedResultEnumZod,
    bloodCheckMemo: z.string().nullish(),
    tgResult: ExaminedResultEnumZod,
    tg: z.number().nullish(),
    hdlResult: ExaminedResultEnumZod,
    hdl: z.number().nullish(),
    ldlResult: ExaminedResultEnumZod,
    ldl: z.number().nullish(),
    acResult: ExaminedResultEnumZod,
    ac: z.number().nullish(),
    pcResult: ExaminedResultEnumZod,
    pc: z.number().nullish(),
    hBeAgResult: ExaminedResultEnumZod,
    hBeAg: z.number().nullish(),
    yearClassId: z.number(),
  })
);

export const PHIUrineZod = PHISharedZod.merge(
  z.object({
    inspect: z.number(),
    urine: ExaminedResultEnumZod,
    uProtein: UrineEnumZod.nullish(),
    uob: UrineEnumZod.nullish(),
    uGlucose: UrineEnumZod.nullish(),
    uPh: z.number().nullish(),
    examDate: zodStringToDateTime(),
    yearClassId: z.number(),
    followUp: z.string().nullish(),
  })
);

export const PHIParasiteZod = PHISharedZod.merge(
  z.object({
    inspect: z.number(),
    parasite: ExaminedResultEnumZod,
    drug: z.boolean(),
    examDate: zodStringToDateTime(),
    yearClassId: z.number(),
  })
);

export const ExtractedPHIUrineZod = PHISharedZod.merge(
  z.object({
    inspect: z.number(),
    urine: ExaminedResultEnumZod,
    uProtein: UrineEnumZod.nullish(),
    uob: UrineEnumZod.nullish(),
    uGlucose: UrineEnumZod.nullish(),
    uPh: z.number().nullish(),
    examDate: zodStringToDateTime(),
    yearClassId: z.number(),
    followUp: z.string().nullish(),
    reUProtein: UrineEnumZod.nullish(),
    reUob: UrineEnumZod.nullish(),
    reUGlucose: UrineEnumZod.nullish(),
    reUPh: z.number().nullish(),
    reExamDate: zodStringToDateTime(),
  })
);
export const ExtractedPHIParasiteZod = PHISharedZod.merge(
  z.object({
    inspect: z.number(),
    parasite: ExaminedResultEnumZod,
    drug: z.boolean(),
    examDate: zodStringToDateTime(),
    reExamDate: zodStringToDateTime(),
    yearClassId: z.number(),
  })
);
export const PHIXRayZod = PHISharedZod.merge(
  z.object({
    examined: z.boolean(),
    xRay: ExaminedResultEnumZod,
    phthisis: ExaminedResultEnumZod,
    calcify: ExaminedResultEnumZod,
    c03: ExaminedResultEnumZod,
    water: ExaminedResultEnumZod,
    c02: ExaminedResultEnumZod,
    bigHeart: ExaminedResultEnumZod,
    brochus: ExaminedResultEnumZod,
    x99: ExaminedResultEnumZod,
    x99State: z.string().nullish(),
    memos: z.string().nullish(),
  })
);

export const StudentPHIAllZod = SimpleStudentZod.merge(
  z.object({
    grade: z.number().int(),
    remark: z.string().nullish(),
    aborigine: z.string().nullish(),
    wh: z.array(PHIWHZod),
    sight: z.array(PHISightZod),
    tee: z.array(PHITeeZod),
    physical: z.array(PHIPhysicalZod),
    // lab: z.array(PHILabZod),
    sundry: z.array(PHISundryWithStudentIdZod),
    disease: z.array(StudentDiseaseZod),
    seriousDisease: z.array(PHISeriousDiseaseZod),
    bodyMindBookN: z.array(PHIBodyMindBookNZod),
    parasite: z.array(PHIParasiteZod),
    urine: z.array(PHIUrineZod),
    classes: z.array(YearSemClassesZod),
    blood: z.array(PHIBloodZod),
    xRay: z.array(PHIXRayZod),
    hospital: z.array(StudentHospitalZod),
    inheritDisease: z.array(StudentInheritDiseaseZod),
    sightSolid: z.array(PHISightSolidZod),
    insurance: z.array(PHIInsuranceZod),
  })
);

export const StudentCheckALLZod = StudentGradeNoSeatZod.merge(
  z.object({
    grade: z.number().int(),
    physical: z.array(PHIPhysicalZod),
    sundry: z.array(PHISundryWithStudentIdZod),
    parasite: z.array(PHIParasiteZod),
    urine: z.array(PHIUrineZod),
    classes: z.array(YearSemClassesZod),
    blood: z.array(PHIBloodZod),
    xRay: z.array(PHIXRayZod),
  })
);

export const StudentTeeZod = SimpleStudentZod.merge(
  z.object({ tee: PHITeeZod.nullish() })
);

export const StudentAllPHIZod = SimpleStudentZod.merge(
  z.object({
    tee: PHITeeZod.nullish(),
    sight: PHISightZod.nullish(),
    wh: PHIWHZod.nullish(),
  })
);

export const StudentInjuryZod = z.object({
  id: z.number().int(),
  yearClassId: z.number().int(),
  year: z.number().int(),
  date: zodStringToDateTime(),
  place: z.string().nullish(),
  placeEnum: InjuryPlaceEnumZod,
  otherPlace: z.string().nullish(),
  isSerious: z.boolean(),
  observationMins: z.number().int(),
  moment: z.string().nullish(),
  momentEnum: InjuryMomentEnumZod,
  temperature: z.number(),
  memos: z.string().nullish(),
  head: z.boolean(), // 受傷部位
  eye: z.boolean(),
  mouth: z.boolean(),
  face: z.boolean(),
  nose: z.boolean(),
  chest: z.boolean(),
  belly: z.boolean(),
  back: z.boolean(),
  shoulder: z.boolean(),
  arm: z.boolean(),
  waist: z.boolean(),
  leg: z.boolean(),
  neck: z.boolean(),
  buttock: z.boolean(),
  perineum: z.boolean(),
  friction: z.boolean(), //外傷部位
  slash: z.boolean(),
  press: z.boolean(),
  strick: z.boolean(),
  twist: z.boolean(),
  burn: z.boolean(),
  sting: z.boolean(),
  fracture: z.boolean(),
  old: z.boolean(),
  aOther: z.boolean(),
  fever: z.boolean(), // 內科
  dizzy: z.boolean(),
  puke: z.boolean(),
  headache: z.boolean(),
  toothache: z.boolean(),
  stomachache: z.boolean(),
  bellyache: z.boolean(),
  diarrhoea: z.boolean(),
  menses: z.boolean(),
  pant: z.boolean(),
  noseBlood: z.boolean(),
  rash: z.boolean(),
  eyeache: z.boolean(),
  hOther: z.boolean(),
  woundCare: z.boolean(), // 處置作為
  ice: z.boolean(),
  hot: z.boolean(),
  rest: z.boolean(),
  noteParent: z.boolean(),
  backHome: z.boolean(),
  hospital: z.boolean(),
  education: z.boolean(),
  mOther: z.boolean(),
});

export const MonthZod = z.object({
  id: z.number(),
  name: z.string(),
});

export const InjuryDateZod = z.object({
  id: z.string(),
  date: z.string(),
});

export const StudentHealthInfoZod = z.object({
  injuries: z.array(StudentInjuryZod),
  disease: z.array(StudentDiseaseZod),
  seriousDisease: z.array(PHISeriousDiseaseZod),
  bodyMindBookN: z.array(PHIBodyMindBookNZod),
  inheritDisease: z.array(PHIInheritDiseaseZod),
  hospital: z.array(StudentHospitalZod),
  insurance: z.array(PHIInsuranceZod),
  sightSolid: z.array(PHISightSolidZod),
});

export const SightAndPreSightNoDiagsZod = z.object({
  differ: z.number().nullish(),
  year: z.string().nullish(),
  preYear: z.string().nullish(),
  preGrade: z.number().nullish(),
  isDilated: z.boolean().default(false),
  isDilating: z.boolean().default(false),
  diopL: z
    .number()
    .nullish()
    .transform((v) => (v == 0 ? null : v)),
  diopR: z
    .number()
    .nullish()
    .transform((v) => (v == 0 ? null : v)),
  eNearL: z
    .number()
    .nullish()
    .transform((v) => (v == 0 ? null : v)),
  eNearR: z
    .number()
    .nullish()
    .transform((v) => (v == 0 ? null : v)),
  preisDilated: z.boolean().default(false),
  preisDilating: z.boolean().default(false),
  preDiopL: z
    .number()
    .nullish()
    .transform((v) => (v == 0 ? null : v)),
  preDiopR: z
    .number()
    .nullish()
    .transform((v) => (v == 0 ? null : v)),
  preENearL: z
    .number()
    .nullish()
    .transform((v) => (v == 0 ? null : v)),
  preENearR: z
    .number()
    .nullish()
    .transform((v) => (v == 0 ? null : v)),
});

export const WHAndPreWHNoDiagsZod = z.object({
  differ: z.number().nullish(),
  year: z.string().nullish(),
  preYear: z.string().nullish(),
  preGrade: z.number().nullish(),
  height: z.number().nullish(),
  heightPre: z.number().nullish(),
});

export const SightNoDiagsZod = z.array(
  StudentGradeNoSeatZod.merge(
    z.object({
      sight: SightZod,
      year: z.number().nullish(),
      sem: z.number().nullish(),
      mAXNear: z.number().nullish(),
      nearDope: z.number().nullish(),
    })
  )
);

export const HospitalZod = z.object({
  id: z.number(),
  name: z.string(),
  schoolId: z.string(),
});

export const CheckYearGradeZod = z.object({
  year: z.number(),
  grade: z.number(),
  classNo: z.number(),
  classId: z.string(),
  className: z.string(),
  gradeName: z.number(),
});

export const UnpivotedBloodCheckZod = z.object({
  years: z.number(),
  gradeID: z.number(),
  items: z.string(),
  m0: z.number(),
  m1: z.number(),
  m2: z.number(),
  m3: z.number(),
  m9: z.number(),
  f0: z.number(),
  f1: z.number(),
  f2: z.number(),
  f3: z.number(),
  f9: z.number(),
  category: z.string().nullish(),
});

export const CheckTotalZod = z.object({
  mTotal: z.number(),
  fTotal: z.number(),
  noProblemM: z.number(),
  noProblemF: z.number(),
});

export const WHSightNoDiagsZod = z.array(
  StudentGradeNoSeatBirthZod.merge(
    z.object({
      sight: SightZod.nullish(),
      wh: PHIWHZod.nullish(),
    })
  )
);

export function transformStudentToPrimitive({ birth, ...rest }: Student): {
  [K in keyof Student]: null | undefined | Primitive;
} {
  return { ...rest, birth: birth.toISO() };
}

export function transformBodyMetricToPrimitive({ examDate, ...rest }: PHIWH): {
  [K in keyof PHIWH]: null | undefined | Primitive;
} {
  return {
    ...rest,
    examDate: examDate?.toString() === '' ? null : examDate?.toISO(),
  };
}

type TransformPrimitive<T extends object, S, D extends Primitive> = {
  [K in keyof T]: Required<T>[K] extends S ? D : T[K];
};

export function transfromDateTimeToPrimitive<T extends {}>(
  source: T
): TransformPrimitive<T, DateTime, string> {
  return Object.entries(source).reduce((des, [key, value]) => {
    return {
      ...des,
      [key]:
        value instanceof DateTime
          ? value.year > 80
            ? value.toISO()
            : null
          : value,
    };
  }, {} as TransformPrimitive<T, DateTime, string>);
}

export type Student = z.infer<typeof StudentZod>;
export type SimpleStudent = z.infer<typeof SimpleStudentZod>;
export type StudentGradeNoSeat = z.infer<typeof StudentGradeNoSeatZod>;
export type StudentGradeNoSeatYearSem = z.infer<
  typeof StudentGradeNoSeatYearSemZod
>;
export type StudentGradeNoSeatBirth = z.infer<
  typeof StudentGradeNoSeatBirthZod
>;
export type StudentWithClassGrade = z.infer<typeof StudentWithClassGradeZod>;
export type PHIWH = z.infer<typeof PHIWHZod>;
export type WH = z.infer<typeof WHZod>;
export type PHISight = z.infer<typeof PHISightZod>;
export type Sight = z.infer<typeof SightZod>;
export type StudentSights = z.infer<typeof StudentSightZod>;
export type PHITee = z.infer<typeof PHITeeZod>;

export type PHIPhysical = z.infer<typeof PHIPhysicalZod>;
export type PHIDisease = z.infer<typeof PHIDiseaseZod>;
export type PHISeriousDisease = z.infer<typeof PHISeriousDiseaseZod>;
export type PHIBodyMindBookN = z.infer<typeof PHIBodyMindBookNZod>;
export type PHISightSolid = z.infer<typeof PHISightSolidZod>;
export type PHIInheritDisease = z.infer<typeof PHIInheritDiseaseZod>;
export type PHIInsurance = z.infer<typeof PHIInsuranceZod>;
export type PHISundry = z.infer<typeof PHISundryZod>;
export type PHIBlood = z.infer<typeof PHIBloodZod>;
export type PHIUrine = z.infer<typeof PHIUrineZod>;
export type PHIUrinePh = z.infer<typeof PHIUrinePhZod>;
export type PHIXRay = z.infer<typeof PHIXRayZod>;
export type PHIParasite = z.infer<typeof PHIParasiteZod>;
export type ExtractedPHIParasite = z.infer<typeof ExtractedPHIParasiteZod>;
export type ExtractedPHIUrine = z.infer<typeof ExtractedPHIUrineZod>;
export type PHISundryWithStudentId = z.infer<typeof PHISundryWithStudentIdZod>;
export type PHISundryGroupByGradeClass = z.infer<
  typeof PHISundryGroupByGradeClassZod
>;
export type SundryExamDate = z.infer<typeof SundryExamDateZod>;
export type StudentDisease = z.infer<typeof StudentDiseaseZod>;
export type BodyMindN = z.infer<typeof BodyMindNZod>;
export type TeeFluorLack = z.infer<typeof TeeFluorLackZod>;
export type NeverFluorStudent = z.infer<typeof NeverFluorZod>;
export type StudentFluor = z.infer<typeof StudentFluorZod>;
export type BrushTeeLack = z.infer<typeof BrushTeeLackZod>;
export type SeriousDisease = z.infer<typeof SeriousDiseaseZod>;
export type Diseases = z.infer<typeof DiseaseZod>;
export type SeriousDiseaseKinds = z.infer<typeof SeriousDiseaseKindZod>;
export type StudentTee = z.infer<typeof StudentTeeZod>;
export type StudentPHIWH = z.infer<typeof StudentPHIWHZod>;
export type StudentPHIAll = z.infer<typeof StudentPHIAllZod>;
export type YearSemClasses = z.infer<typeof YearSemClassesZod>;
export type StudentDiseaseBodyMind = z.infer<typeof StudentDiseaseBodyMindZod>;
export type StudentInjury = z.infer<typeof StudentInjuryZod>;
export type StudentHealthInfo = z.infer<typeof StudentHealthInfoZod>;
export type Month = z.infer<typeof MonthZod>;
export type InjuryDate = z.infer<typeof InjuryDateZod>;
export type SightAndPreSightNoDiags = z.infer<
  typeof SightAndPreSightNoDiagsZod
>;
export type SightNoDiags = z.infer<typeof SightNoDiagsZod>;
export type StudentWH = z.infer<typeof StudentWHZod>;
export type WHAndPreWHNoDiags = z.infer<typeof WHAndPreWHNoDiagsZod>;
export type Hospital = z.infer<typeof HospitalZod>;
export type StudentHospital = z.infer<typeof StudentHospitalZod>;
export type CheckYearGrade = z.infer<typeof CheckYearGradeZod>;
export type UnpivotedBloodCheck = z.infer<typeof UnpivotedBloodCheckZod>;
export type CheckTotal = z.infer<typeof CheckTotalZod>;
export type StudentCheckALL = z.infer<typeof StudentCheckALLZod>;
export type WHSightNoDiags = z.infer<typeof WHSightNoDiagsZod>;
