import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Button, Col, Row, Form } from 'react-bootstrap';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import {
    AuthedLayout,
    EditableTableDiv,
    HeaderDisplayKeysWithType,
    EditableProperties,
} from '../../../components';
import { useClassGrade, useSemGrade } from '../../../hook';
import { StudentGradeNoSeatBirth, WH } from '../../../model';
import apis from '../../../apis';
import { SightContext } from '../../TableHeaders/SightHeader';
import { downloadDataAsExcel, SheetHeaderDisplay } from '../../../utils';
import { whbmiDiag } from '../../../utils/sight';
import { Nullable } from '../../../types';
import { DateTime } from 'luxon';
import { WrapClassBaseLayout, ClassSem } from '../../../layouts';
import ReactDatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
type PageData = StudentGradeNoSeatBirth & WH & { diag: string } & EditableProperties;

const tableHeader: (SheetHeaderDisplay<PageData> &
    HeaderDisplayKeysWithType<PageData>)[] = [
        { display: '座號', property: 'seat' },
        { display: '姓名', property: 'name' },
        { display: '性別', property: 'sex' },
        { display: '身高', property: 'height' },
        { display: '體重', property: 'weight' },
        {
            display: '測量日',
            property: 'examDateWH',
            onRender: (v) => {
                return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : v;
            },
        },
        { display: '體位', property: 'diag' },
        { display: '身高不足標準', property: 'diag' },
        { display: 'BMI', property: 'bmi' },
        {
            display: '實歲',
            property: 'birth',
            onRender: (v) => {
                return calculateAge(v);
            },
            onSheetRender: (v) => {
                return v instanceof DateTime ? calculateAge(v) : "";
            },
        },
        { display: '建議BMI', property: 'fitBMI' },
        { display: '建議體重', property: 'fitWeight' },
    ];

const mapStates = (state: ApplicationState, ownProps: ClassSem) => ({
    ...ownProps,
    ...state.auth,
});
const mapDispatch = ErrorDispatches;

const connector = connect(mapStates, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const wHClassList: FunctionComponent<Props> = ({
    classId,
    className,
    year,
    sem,
    grade,
    no,
    catchErrorForModal,
}) => {
    const { yearSem, element: semGradeElement } = useSemGrade();
    const { selectedClassGrade: classGrade, element: classGradeElement } =
        useClassGrade({
            yearSem
        });
    const [isNotHealthy, setIsNotHealthy] = useState<boolean>(false);
    // const filterstudents = useMemo(filterBmicode, [isNotHealthy]);
    const [students, setStudents] = useState<PageData[]>([]);
    const [isPrintFitWeight, setIsPrintFitWeight] = useState<boolean>(false);
    const [isPrintFitHeight, setIsPrintFitHeight] = useState<boolean>(false);
    const [isPrintHistories, setIsPrintHistories] = useState<boolean>(false);
    const [wHSightClassListPdfDate, setWHSightClassListPdfDate] = useState<DateTime>();

    useEffect(() => {
        if (year && classId) {
            apis
                .getWHClassStudentBmi(year, sem, classId, isNotHealthy)
                .then((r) => {
                    setStudents(
                        r.map(({ sight, wh, ...s }) => ({
                            ...sight,
                            ...wh,
                            ...s,
                            examDateWH: wh?.examDate,
                            diag: whbmiDiag(wh?.bmiCode?.toString()),
                        }))
                    )
                })
                .catch(catchErrorForModal);
        }
    }, [classId, year, sem, isNotHealthy]);


    if (!classId) return <></>;
    return (
        <>
            <Row className='mb-2'>
                僅顯示體位不良者：
                <Col sm={1}>
                    <Form.Check
                        type="checkbox"
                        label="是"
                        name="bmilevel_bad"
                        onChange={(e) => {
                            setIsNotHealthy(e.target.checked);
                        }}
                    />
                </Col>
            </Row>
            <Row className='mb-2'>
                衛教內容設定：
                <Col sm={2}>
                    <Form.Check
                        type="checkbox"
                        label="列印建議體重"
                        name="education"
                        onChange={(e) => {
                            setIsPrintFitWeight(e.target.checked);
                        }}
                    />
                </Col>
                <Col sm={3}>
                    <Form.Check
                        type="checkbox"
                        label="列印歷年身高體重值"
                        name="education"
                        onChange={(e) => {
                            setIsPrintHistories(e.target.checked);
                        }}
                    />
                </Col>
                {/* <Col sm={3}>
                    <Form.Check
                        type="checkbox"
                        label="列印建議身高"
                        name="education"
                        onChange={(e) => {
                            setIsPrintFitHeight(e.target.checked)
                        }}
                    />
                </Col> */}
            </Row>
            <Row className='mb-2'>
                回條內容設定：繳回期限（ex.1999/01/01）
                <Col sm={1}>
                    <ReactDatePicker
                        className="wid-110"
                        dateFormat={'yyyy/MM/dd'}
                        selected={wHSightClassListPdfDate?.toJSDate()}
                        onChange={(date) => {
                            if (date instanceof Date) {
                                setWHSightClassListPdfDate(DateTime.fromJSDate(date));
                            } else {
                                setWHSightClassListPdfDate(undefined);
                            }
                        }}
                    />
                </Col>
            </Row>
            <Row className='mt-3'>
                <Button
                    className="ml-auto mb-2"
                    onClick={() => {
                        let pids = students.filter((s) => s.selected).map((s) => s.pid);
                        pids = pids.length == 0 ? students.map((s) => s.pid) : pids;
                        if (!isPrintHistories) {
                            toast
                                .promise(apis.downloadWH85210ClassListPDF(classId, pids, year, sem, isPrintFitWeight, isPrintFitHeight, wHSightClassListPdfDate), {
                                    pending: '下載中...',
                                    success: '下載成功！',
                                    error: '下載失敗！請查看錯誤資訊。',
                                })
                                .then((blob) => {
                                    const objlink = window.URL.createObjectURL(blob);
                                    const a = document.createElement('a');
                                    a.href = objlink;
                                    a.setAttribute('download', `身高體重測量結果通知單-${className}.pdf`);
                                    document.body.appendChild(a);
                                    a.click();

                                    a.parentNode?.removeChild(a);
                                })
                                .catch(catchErrorForModal);
                        } else {
                            toast
                                .promise(apis.downloadWHHistoryClassListPDF(classId, pids, year, sem, isPrintFitWeight, isPrintHistories, wHSightClassListPdfDate), {
                                    pending: '下載中...',
                                    success: '下載成功！',
                                    error: '下載失敗！請查看錯誤資訊。',
                                })
                                .then((blob) => {
                                    const objlink = window.URL.createObjectURL(blob);
                                    const a = document.createElement('a');
                                    a.href = objlink;
                                    a.setAttribute('download', `身高體重測量結果通知單-${className}.pdf`);
                                    document.body.appendChild(a);
                                    a.click();

                                    a.parentNode?.removeChild(a);
                                })
                                .catch(catchErrorForModal);
                        }

                    }}
                >
                    <span className="feather icon-download"></span>
                </Button>
                <EditableTableDiv
                    headers={tableHeader}
                    values={students}
                    context={SightContext}
                    onSelected={(s) => {
                        // const checkstu = filterstudents.length == 0 ? students : filterstudents;
                        if (s.index !== undefined) {
                            const v = students[s.index];
                            v.selected = s.checked;
                        } else {
                            students.forEach((v) => {
                                v.selected = s.checked;
                            });
                        }
                        setStudents([...students]);
                    }}
                />
            </Row>
        </>
    );
};

function calculateAge(birthDate: DateTime): number {
    const birthDateObj = new Date(birthDate.year, birthDate.month - 1, birthDate.day);

    const today = new Date();
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = today.getMonth() - birthDateObj.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
        age--;
    }

    return age;
}

export const WHClassList = connector(WrapClassBaseLayout(wHClassList));