import React, {
  ChangeEvent,
  FunctionComponent,
  useState,
  ReactNode,
} from 'react';
import {
  Button,
  Form,
  Row,
  FormCheck,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

import XLSX from 'xlsx';
import { z } from 'zod';
import { ConnectedProps, connect } from 'react-redux';
import {
  AuthedLayout,
  EditableTable,
  EditableTableDiv,
  HeaderDisplayKeysWithType,
  OverflowWithTooltip,
} from '../components';
import Apis from '../apis';
import {
  PhiSight,
  PhiSightFile,
  PhiSightFileZod,
  ClassStudent,
  CheckField,
} from '../model';
import { ErrorDispatches } from '../redux/Dispatches';
import { ApplicationState } from '../redux/States';
import { toast } from 'react-toastify';
import { normalizeStudentSight } from './TableHeaders/SightHeader';
import { dateTimeFromString } from '../utils/date';
import {
  CheckShouldUseIdentify,
  assignExists,
  takeClassStudent,
} from '../utils';
import { useActiveSems } from '../hook';

const inputFileParser = z.array(PhiSightFileZod);

const transformCheckedBox = (v: PhiSightFile[keyof PhiSightFile]) => (
  <FormCheck checked={!!v} readOnly type={'checkbox'} />
);
const SightManageMap: Record<string, string> = {
  '1': '長效散瞳劑(阿托平 Atropine)',
  '2': '短效散瞳劑',
  '3': '其他藥物',
  '4': '配鏡矯治',
  '5': '更換鏡片',
  '6': '遮眼治療',
  '7': '配戴隱形眼鏡',
  '7a': '配戴「軟式」隱形眼鏡',
  '7b': '配戴「硬式」隱形眼鏡',
  '7c': '配戴「軟、硬式」隱形眼鏡',
  '8': '角膜塑型片',
  '9': '視力保健衛教',
  '10': '其他',
  '11': '定期檢查',
};
const cachedSightManageMap = SightManageMap;

const headerDisplayKeys: HeaderDisplayKeysWithType<PhiSightFile>[] = [
  { display: '備註', property: 'remark' },
  { display: '身份證字號', property: 'pid' },
  { display: '學號', property: 'sid' },
  { display: '年級', property: 'grade' },
  { display: '班級', property: 'no' },
  { display: '座號', property: 'seat' },
  { display: '學期', property: 'sem' },
  { display: '散瞳治療', property: 'isDilated', onRender: transformCheckedBox },
  { display: '裸視右', property: 'sight0R' },
  { display: '裸視左', property: 'sight0L' },
  { display: '戴鏡右', property: 'sightR' },
  { display: '戴鏡左', property: 'sightL' },
  { display: '散瞳', property: 'isDilating', onRender: transformCheckedBox },
  { display: '近視', property: 'eNear', onRender: transformCheckedBox },
  { display: '近視右', property: 'eNearR' },
  { display: '近視左', property: 'eNearL' },
  { display: '遠視', property: 'eFar', onRender: transformCheckedBox },
  { display: '遠視右', property: 'eFarR' },
  { display: '遠視左', property: 'eFarL' },
  { display: '散光', property: 'eSan', onRender: transformCheckedBox },
  { display: '散光右', property: 'eSanR' },
  { display: '散光左', property: 'eSanL' },
  { display: '弱視', property: 'eWeak', onRender: transformCheckedBox },
  {
    display: '複檢無異狀',
    property: 'noProblem',
    onRender: transformCheckedBox,
  },
  { display: '其他', property: 'eSight99', onRender: transformCheckedBox },
  // { display: '其他陳述', property: 'eSight99State',
  //   style: { maxWidth: '100px' },
  //   onRender: wrapOverflow },//wrapOverflow
  {
    display: '處置代號',
    property: 'manageId',
    onRender: function renderManageId(v, i) {
      if (!Array.isArray(v) || !v.length || typeof v[0] != 'string') {
        return null;
      }
      return (
        <OverlayTrigger
          overlay={
            <Tooltip id={`sight-manage-${i}`}>
              <table>
                <tbody>
                  {v.map((v, idx) => (
                    <tr key={`sight-manage-${idx}`}>
                      <td>{v}</td>
                      <td>:</td>
                      <td>{cachedSightManageMap[v] || '未知處置'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Tooltip>
          }
        >
          <span className="btn btn-outline-secondary border-0 px-2 py-1 m-0 mr-1">
            <span className="feather icon-search" />
          </span>
        </OverlayTrigger>
      );
    },
  },
  { display: '定期檢查', property: 'periodical' },
  // {
  //   display: '測量日',
  //   property: 'examDate',
  //   onRender: (v) => {
  //     return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : null;
  //   },
  // },
];
const validHeaders = ['身分證', '學號', '年級班級座號', '學期', '散瞳治療', '裸視右眼', '裸視左眼', '戴鏡右眼', '戴鏡左眼', '散瞳', '近視右眼', '近視左眼', '遠視右眼', '遠視左眼', '散光右眼', '散光左眼', '近視', '遠視', '散光', '弱視', '其他', '複檢無異狀', '備註', '醫師建議處置', '定期檢查'];

function filterOutNonPhiSightField({
  remark,
  okUpload,
  periodical,
  ...phiSight
}: PhiSightFile): PhiSight {
  return {
    ...phiSight,
    ...normalizeStudentSight(phiSight),
    periodical: periodical ? dateTimeFromString(periodical) : null,
  };
}

function checkOkToUpload(okUpload: boolean, data: PhiSightFile[]): boolean {
  if (okUpload) {
    const a = data.every(function (item) {
      return item.okUpload === true;
    });
    return a;
  } else {
    return false;
  }
}

const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatch = ErrorDispatches;
const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const importPhiSightPage: FunctionComponent<Props> = ({
  user: { semesters, currentSemester },
  catchErrorForModal,
  showError,
}) => {
  const [fileName, setFileName] = useState('檔案'); // 如 :  const [fileName, setFileName] = ''; 可?
  const [fileData, setFileData] = useState([] as PhiSightFile[]);
  const [okToUpload, setOkToUpload] = useState(false);
  const [checkField, setCheckField] = useState(CheckField.None);

  const { selectedSemester, yearSemElement } = useActiveSems(
    semesters,
    currentSemester
  );
  return (
    <AuthedLayout>
      <Row className="mb-2">匯入學期：{yearSemElement}</Row>
      <Row className="mb-2">
        下載匯入格式：
        <a href="/Doc/視力15.xls" style={{ textDecoration: 'underline' }}>
          視力匯入格式
        </a>
      </Row>
      <Row className="mb-2">
        <Form.File
          type="file"
          className="my-file-label"
          id="inputGroupFile01"
          label={fileName}
          accept=".xlsx, .csv, .xls, .ods, .ots"
          onChange={async (e: ChangeEvent<HTMLInputElement>) => {
            e.persist();
            const { files } = e.target;
            try {
              if (files?.length === 1) {
                setFileName(files[0].name);
                const buf = await files[0].arrayBuffer();
                const workShop = XLSX.read(buf, {
                  type: 'buffer',
                  sheets: 0,
                });
                if (workShop.SheetNames[0]) {
                  const jsonData = XLSX.utils.sheet_to_json(
                    workShop.Sheets[workShop.SheetNames[0]],
                    { raw: false, rawNumbers: false }
                  );
                  const headerData = XLSX.utils.sheet_to_json(workShop.Sheets[workShop.SheetNames[0]], { header: 1 });
                  const headers = headerData[0] as string[];
                  const isCorrectFile = validHeaders.every((h: string, i: number) => h === headers[i]);
                  // 檢查表頭是否正確
                  if(!isCorrectFile) {
                    showError('Excel中的表頭欄位不正確，請確認是否選擇了正確的檔案。');
                    return;
                  }
                  const ss = await inputFileParser.parseAsync(jsonData);
                  console.log(ss);
                  if (ss) {
                    const [cf, matchFunc] = CheckShouldUseIdentify(ss);
                    setCheckField(cf);
                    if (cf == CheckField.None) {
                      showError(
                        '匯入之檔案應有唯一使用"身份證"或"學號"或"班級座號"'
                      );
                      return;
                    }
                    await Apis.checkClassesStudentExists(
                      takeClassStudent(ss),
                      cf,
                      selectedSemester
                    )
                      .then((result) => {
                        // console.log('importPhiSight.ts #104',result)
                        // setFileData (merge result&ss)
                        setFileData(assignExists(matchFunc, ss, result));
                        //check ss is okToUpload ,then setOkToUpload(true);
                        setOkToUpload(checkOkToUpload(true, ss));
                      })
                      .catch((e) => {
                        catchErrorForModal(e);
                        setOkToUpload(false);
                      });
                  } else {
                    console.log(ss);
                  }
                }
              }
            } catch (e) {
              console.log('importPhiSight.ts #117', e);
              setFileName('檔案');
              setFileData([]);
              setOkToUpload(false);
              showError('檔案內容不正確，請檢查資料內容格式');
            }
            e.target.value = '';
          }}
          custom
        />
        <Button
          className="mr-0"
          disabled={!okToUpload}
          onClick={() => {
            if (fileData.length > 0 && checkField != CheckField.None)
              toast
                .promise(
                  Apis.insertPhiSight(
                    fileData.map(filterOutNonPhiSightField),
                    checkField,
                    selectedSemester
                  ),
                  {
                    pending: '資料上傳中......',
                    success: '上傳成功！',
                    error: '上傳失敗！請查看錯誤資訊。',
                  }
                )
                .then(() => {
                  setOkToUpload(false);
                })
                .catch(catchErrorForModal);
          }}
        >
          匯入資料
        </Button>
      </Row>
      {
        <Row>
          <EditableTable
            headers={headerDisplayKeys}
            values={fileData}
            scrollable={true}
            onRowRender={({ examDate, ...rest }) => ({
              rowClassName: rest.remark ? 'bg-danger text-white' : '',
            })}
          />
        </Row>
      }
    </AuthedLayout>
  );
};

export const ImportPhiSightPage = connector(importPhiSightPage);
