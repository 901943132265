import React, { FunctionComponent, useEffect, useState, useMemo } from 'react';
import { Button, Col, Row, FormCheck } from 'react-bootstrap';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import {
    AuthedLayout,
    EditableTableDiv,
    HeaderDisplayKeysWithType,
} from '../../../components';
import { useClassGrade, useSemGrade } from '../../../hook';
import { Sight, StudentGradeNoSeatBirth, WH } from '../../../model';
import apis from '../../../apis';
import { SightContext } from '../../TableHeaders/SightHeader';
import { downloadDataAsExcel, SheetHeaderDisplay } from '../../../utils';
import { whbmiDiag } from '../../../utils/sight';
import { Nullable } from '../../../types';
import { DateTime } from 'luxon';

type PageData = StudentGradeNoSeatBirth & Nullable<Sight> & Nullable<WH> & { diag: string };


const tableHeader: (SheetHeaderDisplay<PageData> &
    HeaderDisplayKeysWithType<PageData>)[] = [
        { display: '統編', property: 'pid' },
        { display: '年級', property: 'grade' },
        { display: '班級', property: 'no' },
        { display: '座號', property: 'seat' },
        { display: '姓名', property: 'name' },
        { display: '性別', property: 'sex' },
        {
            display: '生日',
            property: 'birth',
            onRender: (v) => {
                return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : v;
            },
        },
        { display: '體重', property: 'weight' },
        {
            display: '測量日',
            property: 'examDate',
            onSheetRender: (v) => {
                return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : ('value' as string);
            },
            onRender: (v) => {
                return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : v;
            },
        },
        { display: '身高', property: 'height' },
        { display: 'BMI', property: 'bmi' },
        { display: '體位判讀', property: 'diag' },

    ];

const mapState = (app: ApplicationState) => ({ ...app.auth });
const mapDispatch = ErrorDispatches;

const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const whClassBmi: FunctionComponent<Props> = ({
    user,
    catchErrorForModal,
}) => {
    const { yearSem, element: semGradeElement } = useSemGrade();
    const [students, setStudents] = useState<PageData[]>([]);
    const [bmiCode0, setBmiCode0] = useState(false);
    const [bmiCode, setBmiCode] = useState(false);
    const [bmiCode1, setBmiCode1] = useState(false);
    const [bmiCode2, setBmiCode2] = useState(false);

    const filterstudents = useMemo(filterBmicode, [
        bmiCode,
        bmiCode0,
        bmiCode2,
        bmiCode1,
        students
    ]);

    function filterBmicode() {
        let newStudents = students;
        const filtercode: string[] = [];
        if (bmiCode || bmiCode0 || bmiCode1 || bmiCode2) {
            if (bmiCode)
                filtercode.push("-1")
            if (bmiCode0)
                filtercode.push("0")
            if (bmiCode1)
                filtercode.push("1")
            if (bmiCode2)
                filtercode.push("2")
            newStudents = students.filter((s) => {
                if (filtercode.includes(s.bmiCode ?? "")) {
                    return s
                }
            })
            console.log(newStudents)

        }
        return newStudents
    }

    useEffect(() => {
        if (yearSem) {
            apis
                .getWHClassBmi(yearSem.year, yearSem.sem)
                .then((r) =>
                    setStudents(
                        r.map(({ sight, wh, ...s }) => ({
                            ...sight,
                            ...wh,
                            ...s,
                            diag: whbmiDiag(wh?.bmiCode?.toString() ?? ''),
                        }))
                    )
                )
                .catch(catchErrorForModal);
        }
    }, [yearSem?.year, yearSem?.sem]);


    return (
        <AuthedLayout>

            <Row className="justify-content-between">
                <Col>
                    <Row>
                        <Col xs={3} className="mr-3">
                            {semGradeElement}
                        </Col>
                        <Col xs={6} className="mr-3">
                            <Row>
                                <FormCheck
                                    className="mr-4"
                                    label="體重過輕"
                                    checked={bmiCode}
                                    onChange={() => setBmiCode(!bmiCode)}
                                    value={"-1"}
                                />
                                <FormCheck
                                    className="mr-4"
                                    label="體重適中"
                                    checked={bmiCode0}
                                    onChange={() => setBmiCode0(!bmiCode0)}
                                    value={"0"}
                                />
                                <FormCheck
                                    className="mr-4"
                                    label="體重過重"
                                    checked={bmiCode1}
                                    onChange={() => setBmiCode1(!bmiCode1)}
                                    value={"1"}
                                />
                                <FormCheck
                                    className="mr-4"
                                    label="體重超重"
                                    checked={bmiCode2}
                                    onChange={() => setBmiCode2(!bmiCode2)}
                                    value={"2"}
                                />
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <div>
                    <Button
                        disabled={!filterstudents.length}
                        variant="info"
                        className="text-dark"
                        onClick={() => {
                            downloadDataAsExcel({
                                title: `${yearSem?.year}學年${yearSem?.sem}學期_全校體位判讀清單`,
                                values: filterstudents,
                                headers: tableHeader,
                                context: SightContext,
                                footer:
                                    '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                            });
                        }}
                    >
                        Excel 下載
                    </Button>
                </div>
            </Row>
            <hr />
            <Row>
                <EditableTableDiv
                    headers={tableHeader}
                    values={filterstudents}
                    context={SightContext}
                />
            </Row>
        </AuthedLayout>
    );
};

export const WHClassBmi = connector(whClassBmi);