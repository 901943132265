import React, {
  FunctionComponent,
  ReactNode,
  RefObject,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ExaminedResultEnum,
  ExtractedPHIUrine,
  LabSem,
  PHIUrine,
  Semester,
  Student,
  UrineEnum,
  YearSemClasses,
} from '../../model';
import {
  DateTimeField,
  InputDropdownGroupField,
  TextField,
} from '../../components';
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Modal,
  Container,
  Row,
  Table,
} from 'react-bootstrap';
import { Form } from 'react-final-form';
import { I18N } from '../../i18n-raw';
import apis, { ApiError, E_GRADE_INVALID } from '../../apis';
import { DateTime } from 'luxon';

type Props = {
  editable: boolean;
  urine: PHIUrine[];
  student: Student;
  semesters: Semester[];
  yearSems: YearSemClasses[];
  grade: number;
  onApiError: (e: any) => void;
  onValueUpdated: () => void;
};
const Ph = {
  5.0: '3',
  5.5: '2',
  6.0: '1',
  6.5: '1',
  7.0: '1',
  7.5: '1',
  8.0: '1',
  8.5: '1',
  9.0: '1',
};
const NumberMap1 = {
  0: '陰性',
  1: '+',
  2: '++',
  3: '+++',
  4: '+++',
  5: '+/-'
};

export const LabUrineSection: FunctionComponent<Props> = ({
  editable,
  urine,
  student,
  semesters,
  yearSems,
  grade,
  onApiError,
  onValueUpdated,
}) => {
  const [inputing, setInputing] = useState(false);
  const [labSemList, setLabSemList] = useState([] as LabSem[]);
  const currentUrine = useMemo(
    () => {
      const current =  urine.filter((s) => s.grade === grade && s.sem === 1);
      return getUrineFormData(current);
    },
    [grade, urine]
  );

  function onValueUpdate(value: ExtractedPHIUrine, examined: boolean, result: number) {
    const validGrade = yearSems.find((s) => s.grade === grade);
    if (validGrade) {
      const promise = examined
      ? apis.InsertStudentUrine(student.pid, {
          ...DEFAULT_URINE_RESULT,
          ...value,
          urine: result,
          grade: validGrade.grade,
          sem: 1,
          year: validGrade.year,
        })
      : apis.deleteStudentUrine(student.pid, validGrade.year, 1);

      promise.catch(onApiError).then(onValueUpdated);
    } else {
      onApiError(new ApiError(E_GRADE_INVALID));
    }
    setInputing(false);
  }

  function getLabSemList() {
    const validGrade = yearSems.find((s) => s.grade === grade);
    if(!labSemList.length) {
      if(validGrade) {
        apis.getLabSemSettingsNow(validGrade.year)
        .then((s) => {
          if(s.length) {
            setLabSemList(s);
          }
        })
        .catch(onApiError);
      }
    }
  }
  return (
    <>
      <div className="sheet-title">
        實驗室檢查 {grade ?? ''}年級
      </div>
      <Table bordered className="student-phi mb-2">
        <tbody>
          <tr>
            <th>
              尿液
              <Button
                key="cell-edit"
                className="border-0 px-2 py-1"
                variant="outline-primary"
                size="sm"
                onClick={() => {
                  setInputing(true);
                  getLabSemList();
                }}
              >
                <span className="feather icon-edit" />
              </Button>
            </th>
            <td>
              {currentUrine.urine == 9 ?
                (
                  <div>尚未受檢</div>
                ):
                (
                  <div>
                    <div className="text-danger">初/複查結果{currentUrine.urine}</div>
                    <div>
                      初查日期{' '}
                      {currentUrine.examDate?.toFormat('yyyy/MM/dd')}
                    </div>
                    <div>尿蛋白 {currentUrine.uProtein}</div>
                    <div>潛血 {currentUrine.uob}</div>
                    <div>尿糖 {currentUrine.uGlucose}</div>
                    <div>酸鹼度 {currentUrine.uPh}</div>
                    {currentUrine.urine != 0 && (
                      <>
                        <div>
                          複查日期
                          {currentUrine.reExamDate?.toFormat('yyyy/MM/dd')}
                        </div>
                        <div>尿蛋白 {currentUrine.reUProtein}</div>
                        <div>潛血 {currentUrine.reUob}</div>
                        <div>尿糖 {currentUrine.reUGlucose}</div>
                        <div>酸鹼度 {currentUrine.reUPh}</div>
                        <div>追蹤 {currentUrine.followUp}</div>
                      </>
                    )}
                  </div>
                )
              }
            </td> 
          </tr>
        </tbody>
      </Table>
      {inputing ? (
        <ParasiteModel
          urine={currentUrine}
          student={student}
          grade={grade}
          settings={labSemList.filter(x => x.labKind == 2)}
          onClose={() => setInputing(false)}
          onValueUpdate={onValueUpdate}
        />
      ) : (
        <></>
      )}
    </>
  );
};

type ModelProps = {
  urine?: ExtractedPHIUrine;
  student: Student;
  grade: number;
  settings: LabSem[],
  onClose: () => void;
  onValueUpdate: (v: ExtractedPHIUrine, examined: boolean, result: number) => void;
};
function ParasiteModel({
  urine,
  student,
  grade,
  settings,
  onClose,
  onValueUpdate,
}: ModelProps) {
  const [updating, setUpdating] = useState(false);
  const [examResult, setExamResult] = useState(urine?.urine || 0);
  const [examined, setExamined] = useState(!(urine?.urine == 9));
  const [reExamined, setReExamined] = useState((urine?.urine == 1 || urine?.urine == 2 || urine?.urine == 3));
  const divRef = useRef<HTMLDivElement>(null);
  return (
    <Form
      initialValues={urine}
      onSubmit={(v: ExtractedPHIUrine) => {
        if(examResult == ExaminedResultEnum.NoExamined && !updating) {
          setUpdating(true);
        } else {
          onValueUpdate(v, examined, examResult);
        }
      }}
      validateOnBlur
      subscription={{ submitting: true, pristine: true }}
      render={({ handleSubmit, form }) => {
        return (
          <>
            <Modal
              show={true}
              aria-labelledby="contained-modal-title-vcenter"
              backdrop="static"
              dialogClassName="modal-dialog-full"
              centered
            >
              <Modal.Header closeButton onHide={onClose}>
                <Modal.Title id="contained-modal-title-vcenter">
                  實驗室檢查 寄生蟲-{' '}
                  <strong>
                    {student.seat} {student.name}
                  </strong>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body ref={divRef} className="overflow-auto">
                <Table striped bordered hover className="text-break">
                  <colgroup>
                    <col width={'7%'} />
                    <col width={'90%'} />
                  </colgroup>
                  <thead>
                    <tr>
                      <td className="align-middle">項目</td>
                      <td className="align-middle">
                        <Row noGutters className="align-items-center">
                          <Col xs={12} md={2}>
                            身分證： {student.pid}
                          </Col>
                          <Col xs={12} md={2}>
                            檢查年級： {grade}
                          </Col>
                          <Col xs={12} md={2}>
                            初複查結果：{' '}
                            <DropdownButton
                              className="d-inline mr-2"
                              title={examResult == 9 ? '未受檢' : I18N.ExaminationResult[examResult]}
                              onSelect={(k: string | null) => {
                                const examResult = k;
                                setExamResult(examResult ? parseInt(examResult) : 0);
                                setExamined((lastExamine) => {
                                  const nextExamine = k == '9' ? false : true;
                                  // 代入實驗室檢查日期
                                  if (nextExamine) {
                                      if(k != '0' && settings.length) {
                                        if(!lastExamine) {
                                          DEFAULT_URINE_RESULT.examDate = settings[0].checkDate;
                                          DEFAULT_URINE_RESULT.reExamDate = settings[0].checkDate2;
                                          form.reset(DEFAULT_URINE_RESULT);
                                        } else if(urine) {
                                          form.reset({...urine, reExamDate: settings[0].checkDate2});
                                        }
                                      } else if(k == '0' && urine){
                                        urine.examDate = settings.length ? settings[0].checkDate : null;
                                        form.reset({...urine, reExamDate: null, reUPh: null, reUProtein: null, reUob: null, reUGlucose: null, followUp: null});
                                      }
                                  } else {
                                    form.reset(NO_EXAMINED_URINE_RESULT);
                                  }
                                  return nextExamine;
                                });
                                setReExamined((k == '0' || k == '9') ? false : true);
                              }}
                            >
                              <Dropdown.Item eventKey={ExaminedResultEnum.NoProblem}>無異狀</Dropdown.Item>
                              <Dropdown.Item eventKey={ExaminedResultEnum.InitialAbnoraml}>初檢異常</Dropdown.Item>
                              <Dropdown.Item eventKey={ExaminedResultEnum.FollowUpNormal}>複診正常</Dropdown.Item>
                              <Dropdown.Item eventKey={ExaminedResultEnum.FollowUpAbnormal}>複診異常</Dropdown.Item>
                              <Dropdown.Item eventKey={ExaminedResultEnum.NoExamined}>未受檢</Dropdown.Item>
                            </DropdownButton>
                          </Col>
                        </Row>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>初檢</td>
                      <td>
                        <Row noGutters>
                          <Col sm={1} className="my-1">檢查日期：</Col>
                          <Col className="my-1">
                            <DateTimeField
                              property="examDate"
                              disabled={!examined}
                              className="ml-1 wid-200"
                              end={DateTime.now()}
                            />
                          </Col>
                        </Row>
                        <Row noGutters>
                          {generateInput('uProtein', divRef, !examined)}
                          {generateInput('uob', divRef, !examined)}
                        </Row>
                        <Row noGutters>
                          {generateInput('uGlucose', divRef, !examined)}
                          <Col sm={1}>{I18N.Urine['uPh']}：</Col>
                          <Col sm={5}>
                            <InputDropdownGroupField
                              className={`wid-40 mx-1`}
                              disabled={!examined}
                              content={Ph}
                              containerRef={divRef}
                              property="uPh"
                              selectShow="key"
                              resultShow="key"
                            />
                          </Col>
                        </Row>
                      </td>
                    </tr>
                    <tr>
                      <td>複檢</td>
                      <td>
                        <Row noGutters>
                          <Col sm={1} className="my-1">檢查日期：</Col>
                          <Col className="my-1">
                            <DateTimeField
                              property="reExamDate"
                              disabled={!reExamined}
                              className="ml-1 wid-200"
                              end={DateTime.now()}
                            />
                          </Col>
                        </Row>
                        <Row noGutters>
                          {generateInput('reUProtein', divRef, !reExamined)}
                          {generateInput('reUob', divRef, !reExamined)}
                        </Row>
                        <Row noGutters>
                          {generateInput('reUGlucose', divRef, !reExamined)}
                          <Col sm={1}>{I18N.Urine['reUPh']}：</Col>
                          <Col sm={5}>
                            <InputDropdownGroupField
                              className={`wid-40 mx-1`}
                              disabled={!reExamined}
                              content={Ph}
                              containerRef={divRef}
                              property="reUPh"
                              selectShow="key"
                              resultShow="key"
                            />
                          </Col>
                        </Row>
                        <Row noGutters>
                          <Col sm={1}>{I18N.Urine['followUp']}：</Col>
                          <Col sm={12}>
                            <TextField property="followUp" disabled={!reExamined} />
                          </Col>
                        </Row>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="align-middle">
                        <Button className="mx-2" onClick={() => handleSubmit()}>
                          <span className="feather icon-check mr-1" />
                          儲存
                        </Button>
                        <Button
                          className="mx-2"
                          variant="danger"
                          onClick={onClose}
                        >
                          <span className="feather icon-x  mr-1" />
                          取消
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Modal.Body>
            </Modal>
            <Modal
              show={updating}
              aria-labelledby="contained-modal-title-vcenter"
              backdrop="static"
              dialogClassName="modal-dialog-full"
              centered
            >
              <Modal.Header closeButton onHide={() => setUpdating(false)}></Modal.Header>
              <Modal.Body className="overflow-auto">
                <Container>
                  <Row>
                    <Col className="my-2" sm={12}>
                      由於該生初複查結果設定為『未受檢』，若點擊『確定』後該生初複查日期、初複查酸鹼值與追蹤將設為空值，初複查尿蛋白、潛血與尿糖將設為
                      0（陰性），如要修改初複查結果則點擊『取消』返回資料輸入畫面。
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button type="submit" onClick={() => handleSubmit()}>
                  確認
                </Button>
                <Button
                  type="reset"
                  variant="secondary"
                  onClick={() => setUpdating(false)}
                >
                  取消
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        );
      }}
    />
  );
}

function generateInput(
  property: string,
  dropdownRef: RefObject<HTMLElement>,
  disabled: boolean
) {
  return (
    <>
      <Col sm={1}>{I18N.Urine[property]}：</Col>
      <Col sm={5}>
        <InputDropdownGroupField
          className={`wid-40 mx-1`}
          property={property}
          content={NumberMap1}
          type="number"
          containerRef={dropdownRef}
          needGroup={false}
          disabled={disabled}
        />
      </Col>
    </>
  );
}

function getUrineFormData(data: PHIUrine[]): ExtractedPHIUrine {
  if (data && data.length > 0) {
    let urineData: ExtractedPHIUrine | null = null;
    let reExamDate: DateTime | undefined;

    for (const p of data) {
      if (p.inspect === 1) {
        urineData = {
          id: p.id,
          year: p.year,
          sem: p.sem,
          grade: p.grade,
          inspect: p.inspect,
          urine: p.urine,
          examDate: p.examDate ?? undefined,
          uProtein: p.uProtein,
          uob: p.uob,
          uGlucose: p.uGlucose,
          uPh: p.uPh,
          yearClassId: p.yearClassId,
        };
      } else if (p.inspect === 2 && urineData) {
        urineData.reExamDate = p.examDate;
        urineData.reUProtein = p.uProtein;
        urineData.reUGlucose = p.uGlucose;
        urineData.reUPh = p.uPh;
        urineData.reUob = p.uob;
        urineData.followUp = p.followUp;
      }
    }

    if (urineData) {
      return urineData
    }
  }
  return NO_EXAMINED_URINE_RESULT;
}

const DEFAULT_URINE_RESULT: ExtractedPHIUrine = {
  id: 0,
  year: 0,
  sem: 0,
  grade: 0,
  yearClassId: 0,
  inspect: 1,
  urine: ExaminedResultEnum.NoExamined,
  uProtein: UrineEnum.Negetive,
  uob:  UrineEnum.Negetive,
  uGlucose: UrineEnum.Negetive,
  uPh: null,
  reUProtein: UrineEnum.Negetive,
  reUob: UrineEnum.Negetive,
  reUGlucose: UrineEnum.Negetive,
  reUPh: null,
  followUp: null,
};
const NO_EXAMINED_URINE_RESULT: ExtractedPHIUrine = {
  id: 0,
  year: 0,
  sem: 0,
  grade: 0,
  yearClassId: 0,
  inspect: 1,
  urine: ExaminedResultEnum.NoExamined,
  uProtein: UrineEnum.Negetive,
  uob:  UrineEnum.Negetive,
  uGlucose: UrineEnum.Negetive,
  uPh: null,
  reUProtein: UrineEnum.Negetive,
  reUob: UrineEnum.Negetive,
  reUGlucose: UrineEnum.Negetive,
  reUPh: null,
  followUp: null,
};