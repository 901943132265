import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { Button, Col, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import {
    AuthedLayout,
    EditableTableDiv,
    HeaderDisplayKeysWithType,
} from '../../../components';
import { useClassGrade, useSemGrade } from '../../../hook';
import { Sight, StudentGradeNoSeat, YearSem } from '../../../model';
import apis from '../../../apis';
import { SightContext } from '../../TableHeaders/SightHeader';
import { downloadDataAsExcel, SheetHeaderDisplay } from '../../../utils';
import { sightDiag } from '../../../utils/sight';
import { Nullable } from '../../../types';
import { DateTime } from 'luxon';

type PageData = StudentGradeNoSeat & Nullable<Sight> & { diag: string };

const tableHeader: (SheetHeaderDisplay<PageData> &
    HeaderDisplayKeysWithType<PageData>)[] = [
        { display: '年級', property: 'grade', style: { width: "4%" } },
        { display: '班級', property: 'no', style: { width: "4%" } },
        { display: '座號', property: 'seat', style: { width: "4%" } },
        { display: '姓名', property: 'name' },
        {
            display: '散瞳治療',
            property: 'isDilated',
            onRender: boolRender,
            onSheetRender: boolRender,
            style: { width: "3%" }
        },
        { display: '裸視右', property: 'sight0R', style: { width: "4%" } },
        { display: '裸視左', property: 'sight0L', style: { width: "4%" } },
        { display: '戴鏡右', property: 'sightR', style: { width: "4%" } },
        { display: '戴鏡左', property: 'sightL', style: { width: "4%" } },
        {
            display: '散瞳',
            property: 'isDilating',
            onRender: boolRender,
            onSheetRender: boolRender,
            style: { width: "3%" }
        },
        { display: '近視右', property: 'eNearR', style: { width: "4%" } },
        { display: '近視左', property: 'eNearL', style: { width: "4%" } },
        { display: '遠視右', property: 'eFarR', style: { width: "4%" } },
        { display: '遠視左', property: 'eFarL', style: { width: "4%" } },
        { display: '散光右', property: 'eSanR', style: { width: "4%" } },
        { display: '散光左', property: 'eSanL', style: { width: "4%" } },
        { display: '屈光右', property: 'diopR', style: { width: "4%" } },
        { display: '屈光左', property: 'diopL', style: { width: "4%" } },
        { display: '診斷', property: 'diag' },
        { display: '備註', property: 'eSight99State' },
        {
            display: '處置代號',
            property: 'manageID',
            onSheetRender: (v) => (Array.isArray(v) ? v.join(',') : (v as string)),
            onRender: (v) => (Array.isArray(v) ? v.join(',') : (v as string)),
        },
        { display: '醫師建議處置', property: 'manage' },
        {
            display: '定期檢查', property: 'periodical',
            onRender: (v) => {
                return v instanceof DateTime ? v.toFormat('yyyy/MM/dd hh:mm') : null;
            },
            onSheetRender: (v) => {
                return v instanceof DateTime ? v.toFormat('yyyy/MM/dd hh:mm') : "";
            },
        },
    ];

const mapState = (app: ApplicationState) => ({ ...app.auth });
const mapDispatch = ErrorDispatches;

const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

type Argument = {
    yearSem?: YearSem;
};

const useHighNearSight = ({
    yearSem
}: Argument): {
    highnearSightId?: string;
    element: ReactNode;
} => {
    const [chooseId, settype] = useState<string>();
    return {
        highnearSightId: chooseId,
        element: (
            <>
                <DropdownButton
                    title={
                        yearSem
                            ? (chooseId) || '請選擇超過度數'
                            : '請先選擇學年'
                    }
                    onSelect={(k: string | null) => {
                        if (k == null) return;
                        settype(k);
                    }}
                >
                    <Dropdown.Item eventKey="100">100</Dropdown.Item>
                    <Dropdown.Item eventKey="200">200</Dropdown.Item>
                    <Dropdown.Item eventKey="300">300</Dropdown.Item>
                    <Dropdown.Item eventKey="400">400</Dropdown.Item>
                    <Dropdown.Item eventKey="500">500</Dropdown.Item>
                    <Dropdown.Item eventKey="600">600</Dropdown.Item>
                    <Dropdown.Item eventKey="700">700</Dropdown.Item>
                    <Dropdown.Item eventKey="800">800</Dropdown.Item>
                    <Dropdown.Item eventKey="900">900</Dropdown.Item>
                    <Dropdown.Item eventKey="1000">1000</Dropdown.Item>
                </DropdownButton>
            </>
        ),
    };
};

const sightHightNear: FunctionComponent<Props> = ({
    user,
    catchErrorForModal,
}) => {
    const { yearSem, element: semGradeElement } = useSemGrade();
    const { highnearSightId, element: highNearElement } =
        useHighNearSight({
            yearSem,
        });
    const [students, setStudents] = useState<PageData[]>([]);

    useEffect(() => {
        if (yearSem && highnearSightId) {
            apis
                .getSightHighENear(yearSem.year, yearSem.sem, Number(highnearSightId))
                .then((r) =>
                    setStudents(
                        r.map(({ sight, ...s }) => ({
                            ...sight,
                            ...s,
                            diag: sightDiag(sight),
                        }))
                    )
                )
                .catch(catchErrorForModal);
        }
    }, [highnearSightId, yearSem?.year, yearSem?.sem]);

    return (
        <AuthedLayout>
            <Row className="justify-content-between">
                <Col>
                    <Row>
                        <Col xs={3} className="mr-3">
                            {semGradeElement}
                        </Col>
                        <Col xs={3} className="mr-3">
                            <Row>
                                {highNearElement}
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <div>
                    <Button
                        disabled={!students.length}
                        variant="success"
                        className="text-dark"
                        onClick={() => {
                            downloadDataAsExcel({
                                title: `${yearSem?.year}學年${yearSem?.sem}學期_近視度數超過${highnearSightId}度名單`,
                                values: students,
                                headers: tableHeader,
                                context: SightContext,
                                groupBy: (v) => `${highnearSightId}度`,
                                footer:
                                    '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                            });
                        }}
                    >
                        Excel 下載
                    </Button>
                </div>
            </Row>
            <hr />
            <Row>
                <EditableTableDiv
                    headers={tableHeader}
                    values={students}
                    context={SightContext}
                />
            </Row>
        </AuthedLayout>
    );
};

function boolRender(v: unknown) {
    return v ? '是' : '否';
}
export const SightHightNear = connector(sightHightNear);
